<template>
  <div>
    <welcome-conference-banner class='mb-8'/>
    <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-48'>
      <div
        class='background-style text-white py-4 px-4 lg:py-16 lg:px-16 flex-shrink-0'
        :style='`${backgroundStyle}`'>
        <div class='lg:sticky lg:top-32'>
          <h2 class='text-2xl uppercase'>{{ eventConfigWelcomeMessageTitle }}</h2>
          <ul class='text-sm mt-4'>
            <li 
              class='py-1 my-1 hover:font-semibold cursor-pointer standard-transition'
              v-scroll-to="'#korean-welcome'">
              Korean Society of Head and Neck Surgery
            </li>
            <li 
              class='py-1 my-1  hover:font-semibold cursor-pointer standard-transition'
              v-scroll-to="'#japanese-welcome'">
              Japan Society for Head and Neck Surgery
            </li>
            <li 
              class='py-1 my-1  hover:font-semibold cursor-pointer standard-transition'
              v-scroll-to="'#taiwanese-welcome'">
              Taiwan Head and Neck Society
            </li>
          </ul>
        </div>
      </div>
      <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
        <div id='korean-welcome'>
          <h1 class='text-2xl font-semibold mb-4'>Korean Society of Head and Neck Surgery</h1>
          <welcome-message-text class='leading-6 max-w-xl text-sm mb-4' />
          <img class='message-author' :src='eventConfigPresidentImageUrl' />
          <greeters :greeters='greeters' />
        </div>
        <div id='japanese-welcome'
          class='mt-16 border-t pt-8'>
          <h1 class='text-2xl font-semibold mb-4'>Japan Society for Head and Neck Surgery</h1>
          <vimeo-vod-player
            :vimeo-video-id='japaneseVideoDetails.vimeoVideoId'
            :autoplay='false'
            :mute='false'
            :loop='false'
            :default-volume='0.80' />
          <div class='mt-4'>
            <h2 class='font-medium mt-2 leading-4'>
              {{ japaneseVideoDetails.title }}
              {{ japaneseVideoDetails.name }}
            </h2>
            <h3 class='text-gray-400 text-sm'>
              {{ japaneseVideoDetails.position }} of the {{ japaneseVideoDetails.societyName }}
            </h3>
          </div>
        </div>
        <div id='taiwanese-welcome'
          class='mt-16 border-t pt-8'>
          <h1 class='text-2xl font-semibold mb-4'>Taiwan Head and Neck Society</h1>
          <vimeo-vod-player
            :vimeo-video-id='taiwanVideoDetails.vimeoVideoId'
            :autoplay='false'
            :mute='false'
            :loop='false'
            :default-volume='0.80' />
          <div class='mt-4'>
            <h2 class='font-medium mt-2 leading-4'>
              {{ taiwanVideoDetails.title }}
              {{ taiwanVideoDetails.name }}
            </h2>
            <h3 class='text-gray-400 text-sm'>
              {{ taiwanVideoDetails.position }} of the {{ taiwanVideoDetails.societyName }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VimeoVodPlayer          from '@/components/VimeoVodPlayer.vue'
import Greeters                from '@/components/welcome/Greeters.vue'
import WelcomeMessageText      from '@/components/welcome/WelcomeMessageText.vue'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'

export default {
  name: 'Welcome',
  components: {
    VimeoVodPlayer,
    Greeters,
    WelcomeMessageText,
    WelcomeConferenceBanner,
  },
  props: [
    'customBackgroundStyle',
  ],
  data () {
    return {
      selectedSociety: 'korean',
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'eventConfigWelcomeMessageTitle',
      'eventConfigPresidentImageUrl',
      'eventConfigPresidentName',
      'eventConfigPresidentPosition',
      'eventConfigPresidentSignatureImageUrl',
      'eventConfigGreeters',
    ]),
    backgroundStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    greeters () {
      if (this.eventConfigGreeters.length) {
        return this.eventConfigGreeters
      } else {
        return [
          {
            greeterName:       this.eventConfigPresidentName,
            greeterTitle:      this.eventConfigPresidentPosition,
            signatureImageUrl: this.eventConfigPresidentSignatureImageUrl,
          }
        ]
      }
    },
    showKorean () {
      return this.selectedSociety === 'korean'
    },
    showJapanese () {
      return this.selectedSociety === 'japanese'
    },
    showTaiwanese () {
      return this.selectedSociety === 'taiwanese'
    },
    vimeoVideoId () {
      return this.selectedSociety === 'japanese' ? this.japaneseVideoDetails.vimeoVideoId : this.taiwanVideoDetails.vimeoVideoId
    },
    selectedSocietyTitle () {
      switch (this.selectedSociety) {
        case 'korean':
          return 'Korean Society of Head and Neck Surgery'
        case 'japanese':
          return 'Japan Society for Head and Neck Surgery'
        case 'taiwanese':
          return 'Taiwan Head and Neck Society'
        default:
          return 'Tri-HN Society 2022'
      }
    },
    japaneseVideoDetails () {
      return {
        vimeoVideoId: '751935495',
        defaultVolume: 0.80,
        societyName: 'Japan Society for Head and Neck Surgery',
        position: 'President',
        title: 'Prof.',
        name: 'Shigeru HIRANO',
      }
    },
    taiwanVideoDetails () {
      return {
        vimeoVideoId: '751935597',
        defaultVolume: 0.80,
        societyName: 'Taiwan Head and Neck Society',
        position: 'President',
        title: 'Prof.',
        name: 'Pen-Yuan Chu',
      }
    },
  },
  methods: {
    isSelectedSociety (society) {
      return (this.selectedSociety === society) ? 'font-semibold list-disc' : 'list-none hover:list-disc'
    },
  }
}
</script>

<style lang='scss' scoped>

.background-style {
  background: transparent; 
  width: 100%;
}
  
.background-style img {
  position: static;
  right: 1rem; 
  bottom: 0rem; 
  width: 120px;
}
    
.message-author {
  width: 5rem;
}

  @media (min-width: 1024px) {
    .background-style {
      width: 26rem;
      height: auto;
    }

    .message-author {
      width: 180px;
      display: block;
    }

  .background-style img {
    position: absolute;
    right: -4rem; 
    top: 4rem; 
    width: 180px;
    display: block;
  }

}
</style>



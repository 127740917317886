<template>
  <div>
    <vimeo-player
      ref='vodPlayer'
      :options='options'
      :video-id='vimeoVideoId'
      @play='playVod'
      @fullscreenchange='fullscreenUpdate' />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: [
    'vimeoVideoId', 
    'autoplay', 
    'mute',
    'loop',
    'defaultVolume',
  ],
  name: 'VimeoVodPlayer',
  data () {
    return {
      options: {
        autoplay: this.autoplay,
        responsive: true,
        title: false,
        byline: false,
        speed: false,
        muted: this.mute,
        loop: this.loop
      },
      playVodForTheFirstTime: true,
    }
  },
  methods: {
    ...mapMutations([
      'setIsFullscreenVideo'
    ]),
    fullscreenUpdate (resp) {
      this.setIsFullscreenVideo(resp.fullscreen)
    },
    playVod () {
      if (this.playVodForTheFirstTime) {
        this.playVodForTheFirstTime = false
        if (!this.autoplay && this.defaultVolume) {
          this.$nextTick(() => {
            this.$refs.vodPlayer.player.setVolume(this.defaultVolume)
          }) 
        }
      }
    },
  },
}
</script>
